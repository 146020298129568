import { render, staticRenderFns } from "./addinsu.vue?vue&type=template&id=60c9ae34&scoped=true&"
import script from "./addinsu.vue?vue&type=script&lang=js&"
export * from "./addinsu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c9ae34",
  null
  
)

export default component.exports